import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetData_V3 } from '../../../api/apiHelper_V3'
import {
  caseStatus,
  decryptAes256Text,
  extractCustomerLeadId
} from '../../utility/constants'

import Layout from '../Layout'
import {
  getKycServiceStatus,
  getPaymentStatus,
  getPreviousJourneyDetails,
  processFlow
} from '../Homepage/TPApiCall'
import { toast } from 'react-toastify'
import { dispatchMotorQuote } from '../../../store/actions/userActions'

const SuccessMessage = () => {
  const motorRequest = useSelector(state => state.root.motorRequest)
  const selectedPlan = useSelector(state => state.root.selectedPlan)
  const [spinner, setSpinner] = useState(false)
  const [paymentStatusState, setPaymentStatusState] = useState('')
  const [kycRedirection, setKycRedirection] = useState(false)
  const [downloadLoader, setDownloadLoader] = useState(true)
  const [policyDetails, setPolicyDetails] = useState({})
  const [policyDoc, setPolicyDoc] = useState('')
  const [paramsData, setParamsData] = useState({
    caseId: ''
  })
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const caseId = searchParams.get('caseId')
    setParamsData({
      caseId: caseId
    })
    const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
    getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
    paymentStatus(caseId)
  }, [])

  const paymentStatus = async data => {
    setDownloadLoader(true)
    try {
      const response = await getPaymentStatus(data)
      setPaymentStatusState(response?.data?.caseStatus)

      if (
        response?.data?.caseStatus === 'Payment Success' ||
        response?.data?.caseStatus === 'Policy Document Success'
      ) {
        if (response?.data?.isKycStatusServiceCall) {
          const kycService = await kycServiceStatus()
          if (kycService === 'success') {
            try {
              const documentResponse = await getPolicyDocument(
                response?.data?.caseId
              )

              if (
                documentResponse?.status === 'success' &&
                documentResponse?.data?.caseStatus === 'Policy Document Success'
              ) {
                const decryptPolicyDocumentUrl = decryptAes256Text(
                  documentResponse?.data?.policyDocumentUrl,
                  process.env.REACT_APP_DECRYPTION_KEY
                )
                setPolicyDetails({
                  policyNumber: documentResponse?.data?.policyNo,
                  policyDocumentUrl: decryptPolicyDocumentUrl
                })
                const dataForFlow = {
                  ...processFlowObjMotor,
                  customerLeadId: motorRequest?.customerLeadId,

                  processDiscription: 'pdfGeneration',
                  step: 'step13',
                  step13: {
                    ...processFlowObjMotor.step13,
                    pdfGeneration: {
                      pdfUrl: decryptPolicyDocumentUrl,
                      pdfStatus: 'pdfGenerated'
                    },
                    motorRequest: { ...motorRequest }
                  }
                }
                processFlow(dataForFlow)
              } else {
                toast.error(
                  documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `${documentResponse?.errors?.[0]?.displayMessage}`,
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                  }
                )
                const dataForFlow = {
                  ...processFlowObjMotor,
                  customerLeadId: motorRequest?.customerLeadId,

                  processDiscription: 'pdfGeneration',
                  step: 'step13',
                  step13: {
                    ...processFlowObjMotor.step13,
                    pdfGeneration: documentResponse?.data?.displayMessage
                      ? `${documentResponse.data.displayMessage}`
                      : documentResponse?.data?.errors
                      ? `${documentResponse?.data?.errors[0].displayMessage}`
                      : `${documentResponse?.errors?.[0]?.displayMessage}`,
                    motorRequest: { ...motorRequest }
                  }
                }
                processFlow(dataForFlow)
              }
            } catch (error) {
              toast.error(
                documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `${documentResponse?.errors?.[0]?.displayMessage}`,
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light'
                }
              )
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,

                processDiscription: 'pdfGeneration',
                step: 'step13',
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `${documentResponse?.errors?.[0]?.displayMessage}`,
                  motorRequest: { ...motorRequest }
                }
              }
              processFlow(dataForFlow)
            }
            setKycRedirection(true)
            dispatchMotorQuote('kycServiceStatusResponse', kycService)
            dispatchMotorQuote('redirection', true)
            setTimeout(() => {
              window.open(kycService?.data?.link, '_blank')
            }, 3000)
          } else {
            return
          }
        } else {
          try {
            const documentResponse = await getPolicyDocument(
              response?.data?.caseId
            )
            if (
              // documentResponse?.status === 'success' &&
              documentResponse?.data?.caseStatus === 'Policy Document Success'
            ) {
              const decryptPolicyDocumentUrl = decryptAes256Text(
                documentResponse?.data?.policyDocumentUrl,
                process.env.REACT_APP_DECRYPTION_KEY
              )
              setPolicyDetails({
                policyNumber: documentResponse?.data?.policyNo,
                policyDocumentUrl: decryptPolicyDocumentUrl
              })
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,
                processDiscription: 'pdfGeneration',
                step: 'step13',
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: {
                    pdfUrl: decryptPolicyDocumentUrl,
                    pdfStatus: 'pdfGenerated'
                  },
                  motorRequest: { ...motorRequest }
                }
              }
              processFlow(dataForFlow)
            } else {
              toast.error(
                documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `${documentResponse?.errors?.[0]?.displayMessage}`,
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light'
                }
              )
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,

                processDiscription: 'pdfGeneration',
                step: 'step13',
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `${documentResponse?.errors?.[0]?.displayMessage}`,
                  motorRequest: { ...motorRequest }
                }
              }
              processFlow(dataForFlow)
            }
          } catch (error) {
            toast.error(
              documentResponse?.data?.displayMessage
                ? `${documentResponse.data.displayMessage}`
                : documentResponse?.data?.errors
                ? `${documentResponse?.data?.errors[0].displayMessage}`
                : `${documentResponse?.errors?.[0]?.displayMessage}`,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
              }
            )
            const dataForFlow = {
              ...processFlowObjMotor,
              customerLeadId: motorRequest?.customerLeadId,
              processDiscription: 'pdfGeneration',
              step: 'step13',
              step13: {
                ...processFlowObjMotor.step13,
                pdfGeneration: documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `${documentResponse?.errors?.[0]?.displayMessage}`,
                motorRequest: { ...motorRequest }
              }
            }
            processFlow(dataForFlow)
          }
        }
      } else {
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,

          processDiscription: 'paymentGateway',
          step: 'step12',
          step12: {
            ...processFlowObjMotor.step12,
            paymentStatus: 'failure',
            motorRequest: { ...motorRequest }
          }
        }
        processFlow(dataForFlow)
      }
    } catch (error) {
      console.error('Error fetching payment status', error)
    } finally {
      setDownloadLoader(false)
    }
  }

  const kycServiceStatus = async () => {
    const postData = {
      caseId: motorRequest?.caseId,
      vehicleType: motorRequest?.vehicleType
    }
    const kycService = await getKycServiceStatus(postData)
    if (kycService?.data?.kycStatus === 'failure') {
      setKycRedirection(true)
      dispatchMotorQuote('kycServiceStatusResponse', kycService)
      dispatchMotorQuote('redirection', true)
      window.open(kycService?.data?.redirectionUrl, '_blank')
      return kycService?.data?.kycStatus
    } else {
      setKycRedirection(false)
      return kycService?.data?.kycStatus
    }
  }


  const getPolicyDocument = async data => {
    setSpinner(true)
    try {
      const documentResponse = await GetData_V3(
        `motor/getPolicyDocument/${data}`
      )
      if (documentResponse?.data?.caseStatus === 'Policy Document Success') {
        let response = decryptAes256Text(
          documentResponse?.data?.policyDocumentUrl,
          process.env.REACT_APP_DECRYPTION_KEY
        )
        console.log('docRes', response)
        setPolicyDoc(response)
        window.open(response, '_blank')
        setSpinner(false)
      } else {
        toast.error(
          documentResponse?.data?.displayMessage
            ? `${documentResponse.data.displayMessage}`
            : documentResponse?.data?.errors
            ? `${documentResponse?.data?.errors[0].displayMessage}`
            : `${documentResponse?.errors?.[0]?.displayMessage}`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }
        )
        setSpinner(false)
      }
    } catch (error) {
      return error
    }
  }

  const handlePdf = () => {
    const searchParams = new URLSearchParams(location.search)
    const caseId = searchParams.get('caseId')

    setParamsData({
      caseId: caseId
    })
    const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
    getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
    paymentStatus(caseId)
  }


    const openPdf = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <Layout>
      {kycRedirection ? (
        <div style={{ marginTop: '200px', height: '100vh' }}>
          <div className='kyc_fail'>
            <span>Policy Purcahsed SuccessFully</span>
            <div>But Your KYC Failed! Please Click the Button To Redo KYC</div>
            <button
              onClick={() => kycServiceStatus()}
              className='btn btn-primary fs-5 px-2 py-2 mt-1'
            >
              {downloadLoader ? (
                <div class='spinner-border text-success' role='status'>
                  <span class='sr-only'></span>
                </div>
              ) : (
                <span>Verify Kyc</span>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div
          className='card text-center'
          style={{
            width: '100%',
            height: '100%',
            marginTop: '3rem',
            overflow: 'hidden'
          }}
        >
          <div>
            <div className='js-container'></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '30px',
                position: 'relative',
                width: '100%',
                // height: '100vh',
                // overflow: 'hidden',
                top: '100px',
                left: '0px'
              }}
            >
              <div className='success-message'>
                {paymentStatusState === caseStatus?.paymentSuccess ||
                paymentStatusState === caseStatus?.policyDocSuccess ? (
                  <>
                    {' '}
                    <div class='circle-loader'>
                      <div class='checkmark-1 draw'></div>
                    </div>
                    <h1>Congratulations!</h1>
                    <p className='fs-3 mt-2'>Policy purchased successfully.</p>
                    <div className='mt-3'>
                      {paramsData.caseId ? (
                        <button
                          className='btn btn-primary fs-5 px-2 py-2 mt-1'
                          onClick={() =>
                            policyDoc !== ''
                              ? openPdf(policyDoc)
                              : kycRedirection
                              ? kycServiceStatus()
                              : null
                          }
                        >
                          {downloadLoader ? (
                            <div
                              class='spinner-border text-light'
                              role='status'
                            >
                              <span class='sr-only'></span>
                            </div>
                          ) : kycRedirection ? (
                            <span>Verify Kyc</span>
                          ) : (
                            'Download PDF'
                          )}
                        </button>
                      ) : '' }
                    </div>
                  </>
                ) : paymentStatusState === caseStatus?.paymentFailure ? (
                  <>
                    <div class='o-circle c-container__circle o-circle__sign--failure'>
                      <div class='o-circle__sign'></div>
                    </div>
                    <h1 className='payment_failure_text'>Payment Failed</h1>
                    <button
                      className='btn btn-danger fs-3 px-3 py-2 mt-3'
                      onClick={() => navigate(`/proposal/${urlCustomerLeadId}`)}
                    >
                      Retry
                    </button>
                  </>
                ) : kycRedirection ? (
                  <div className='loader_circle'>
                    <div class='payment-loader'>
                      <div class='pad'>
                        <div class='chip'></div>
                        <div class='line line1'></div>
                        <div class='line line2'></div>
                      </div>
                      <div class='loader-text'>
                        Your KYC has been failed. Redirecting you to the
                        Insurer's Portal...
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='loader_circle'>
                    <div class='payment-loader'>
                      <div class='pad'>
                        <div class='chip'></div>
                        <div class='line line1'></div>
                        <div class='line line2'></div>
                      </div>
                      <div class='loader-text'>
                        Please wait while payment is pending
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default SuccessMessage